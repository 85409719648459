import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Input = ({modifiers, ...props}) => {
    return (
        <input {...props} className={classNames({
            'form__input': true,
            ...modifiers
        })} />
    );
};

export default Input;