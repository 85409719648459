import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import PageHeading from '../components/pageheading';
import Publications from '../components/publications';

// markup
const PublicationsPage = () => {
  return (
    <Layout>
        <Helmet>
            <title>Delfi.se - Analyser & Insikter</title>
        </Helmet>
          <PageHeading heading="Analyser & Insikter" preamble="Våra analyser & insikter ger värdefull kunskap om utvecklingen på den svenska foodservice- och dagligvarumarknaden för branschens aktörer och beslutsfattare." />
        <Publications />
    </Layout>
  )
}

export default PublicationsPage;