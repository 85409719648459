import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Textarea = ({ modifiers, ...props }) => {
    return (
        <textarea {...props} className={classNames({
            'form__textarea': true.valueOf,
            ...modifiers
        })}></textarea>
    );
};

export default Textarea;