import React from 'react';
import classNames from 'classnames';
import './save-button.scss';

const SaveButton = props => {
    
    return (
        <button className={classNames({
            'button': true,
            'report-save-btn': true,
            'report-save-btn--is-loading': props.isLoading,
            'report-save-btn--is-saved': props.isSaved,
            ...props.modifiers
        })} type={props.type ? props.type : 'button'} {...props.attr}>
            <span className="report-save-btn__inner report-save-btn__inner--normal">
                {props.text}
            </span>
            <span className="report-save-btn__inner report-save-btn__inner--loading">
                <span className="spinner-bounce">
                    <span className="bounce1"></span>
                    <span className="bounce2"></span>
                    <span className="bounce3"></span>
                </span>
            </span>

            <span className="report-save-btn__inner report-save-btn__inner--saved"><span>{props.textSaved}</span></span>
        </button>
    );
};

export default SaveButton;