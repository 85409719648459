import React, { Component } from 'react';
import Checkbox from '../checkbox';
import Input from '../input';
import Textarea from '../textarea';
import classNames from 'classnames';
import SaveButton from '../savebutton';
import Success from './success';
import './styles.scss';

class GroceryMapOrderForm extends Component {

    state = {
        isValid: true,
        isNameError: false,
        isEmailError: false,
        isLoading: false,
        isSaved: false,
        mail: {
            grocerymap: false,
            name: '',
            email: '',
            company: '',
            org: '',
            phone: '',
            address: '',
            postal: '',
            other: ''
        }
    };

    buildMailBody() {
        let body = [];

        body.push('Tack för din beställning!');
        body.push('Beställning: Dagligvarukartan 2024');
        body.push('');
        body.push('Beställare:');
        body.push(`Namn: ${this.state.mail.name}`);
        body.push(`Epost: ${this.state.mail.email}`);
        body.push(`Företag: ${this.state.mail.company}`);
        body.push(`Org.Nr: ${this.state.mail.org}`);
        body.push(`Telefon: ${this.state.mail.phone}`);
        body.push(`Adress: ${this.state.mail.address}`);
        body.push(`Postadress: ${this.state.mail.postal}`);
        body.push(`Övrig info: ${this.state.mail.other}`);
        body.push('');
        body.push('--');
        body.push('Detta meddelande är skickat från Delfi MarknadsPartner AB - www.delfi.se')
        
        return body.join('\n');  
    }

    async sendMail(e) {
        e.preventDefault();

        if (!this.validate()) {
            return;
        }
            
        this.setState({
            isLoading: true
        });

        //Build mail body
        const body = this.buildMailBody();
        
        const res = await fetch('/api/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                subject: 'Beställning av Dagligvarukartan',
                cc: this.state.mail.email,
                mailBody: body
            })
        });

        await res.json();
        this.setState({
            isValid: true,
            isNameError: false,
            isEmailError: false,
            isLoading: false,
            isSaved: true,
            mail: {
                grocerymap: false,
                name: '',
                email: '',
                company: '',
                org: '',
                phone: '',
                address: '',
                postal: '',
                other: ''
            }
        });
    }

    toggleModal() {
        this.props.close();

        this.setState({
            isValid: true,
            isNameError: false,
            isEmailError: false,
            isLoading: false,
            isSaved: false,
            mail: {
                grocerymap: false,
                name: '',
                email: '',
                company: '',
                org: '',
                phone: '',
                address: '',
                postal: '',
                other: ''
            }
        });
    }

    updateValues(param, value) {
        const mail = {...this.state.mail};

        mail[param] = value;
        this.setState({
            mail
        });
    }

    validate() {
        let isValid = true;
        let isNameError = false;
        let isEmailError = false;
        if (this.state.mail.name === '') {
            isValid = false;
            isNameError = true;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.mail.email)) {
            isValid = false;
            isEmailError = true;
        }

        this.setState({
           isValid,
           isNameError,
           isEmailError
        });

        return isValid;
    }

    render () {
        return (
            <div className={classNames({
                'order-form': true,
                'order-form--is-loading': this.state.isLoading,
                'order-form--is-finished': this.state.isSaved
            })}>
                {!this.state.isSaved &&
                    <form method="post" onSubmit={e => this.sendMail(e)}>
                        <div className="order-form__products">
                            <div className="order-form__row">
                                <label>
                                    <div className="order-form__controls">    
                                        <Checkbox name="grocerymap" onChange={() => this.updateValues('grocerymap', !this.state.mail.grocerymap)} checked={this.state.mail.grocerymap} />
                                        <div className="order-form__label">
                                            <span className="order-form__label-heading">Dagligvarukartan 2024</span>
                                        </div>
                                    </div>
                                </label>
                                <div className="order-form__price"><strong>Kostnadsfritt</strong></div>
                            </div>
                        </div>

                        <label className="order-form__label">Namn*</label>
                        <Input name="name" 
                               onChange={(e) => this.updateValues('name', e.currentTarget.value)} 
                               value={this.state.mail.name} 
                               modifiers={{
                                'form__input--is-error': this.state.isNameError
                            }} /> 

                        <label className="order-form__label">Epost*</label>
                        <Input name="email" 
                               onChange={(e) => this.updateValues('email', e.currentTarget.value)} 
                               value={this.state.mail.email} 
                               modifiers={{
                                'form__input--is-error': this.state.isEmailError
                            }} /> 

                        <label className="order-form__label">Företag</label>
                        <Input name="company" onChange={(e) => this.updateValues('company', e.currentTarget.value)} value={this.state.mail.company} /> 

                        <label className="order-form__label">Org.Nr</label>
                        <Input name="org" onChange={(e) => this.updateValues('org', e.currentTarget.value)} value={this.state.mail.org} /> 

                        <label className="order-form__label">Telefon</label>
                        <Input name="phone" onChange={(e) => this.updateValues('phone', e.currentTarget.value)} value={this.state.mail.phone} /> 

                        <label className="order-form__label">Adress</label>
                        <Input name="address" onChange={(e) => this.updateValues('address', e.currentTarget.value)} value={this.state.mail.address} /> 

                        <label className="order-form__label">Postadress</label>
                        <Input name="postal" onChange={(e) => this.updateValues('postal', e.currentTarget.value)} value={this.state.mail.postal} /> 

                        <label className="order-form__label">Övrig info - Ange önskemål om tryckt publikation finns	</label>
                        <Textarea name="other" onChange={(e) => this.updateValues('other', e.currentTarget.value)} value={this.state.mail.other} /> 
                        {this.state.isNameError && this.state.isEmailError &&
                            <p className="order-form__error">🥸 Namn och Epost är obligatoriska fält. </p>
                        }
                        {!this.state.isNameError && this.state.isEmailError &&
                            <p className="order-form__error">🥸 Epost är ett obligatoriskt fält.</p>
                        }
                        {this.state.isNameError && !this.state.isEmailError &&
                            <p className="order-form__error">🥸 Namn är ett obligatoriskt fält.</p>
                        }
                        <SaveButton
                                type="submit"
                                isLoading={this.state.isLoading}
                                isSaved={this.state.isSaved} 
                                text="Skicka beställning" 
                                textSaving="Skickar"
                                textSaved="Tack för din beställning!"
                                attr={{
                                    disabled: this.props.isSaving
                                }}
                                modifiers={{
                                    'button--large': true,
                                    'demo-form__submit': true,
                                    'order-form__submit': true
                                }}>Skicka beställning</SaveButton>
                        <button className="order-form__cancel" type="button" onClick={() => this.toggleModal()}>Avbryt</button>
                    </form>
                }
                {this.state.isSaved &&
                    <>
                        <p className="order-form__success">Tack för din beställning! Vi har nu mottagit din beställning och en bekräftelse är skickad till den e-postadress som du angivit i formuläret.</p>
                        <Success isFinished={this.state.isSaved} />
                        <div className="districts__takeover-controls">                                
                            <button onClick={() => this.toggleModal()} className="order-form__close-btn" type="button">Stäng</button>
                        </div>                        
                    </>
                }
                <button className="takeover__close" onClick={() => this.toggleModal()} type="button"></button>
            </div>
        );
    }
}

export default GroceryMapOrderForm;
