import React, { Component } from 'react';
import Checkbox from '../checkbox';
import Input from '../input';
import Textarea from '../textarea';
import classNames from 'classnames';
import SaveButton from '../savebutton';
import Success from './success';
import './styles.scss';

class FastfoodOrderForm extends Component {

    state = {
        isValid: true,
        isNameError: false,
        isEmailError: false,
        isOrderEmailError: false,
        isLoading: false,
        isSaved: false,
        mail: {
            fastfoodguide23: false,
            fastfoodguide23package: false,
            fastfoodguide23presentation: false,
            name: '',
            email: '',
            company: '',
            org: '',
            phone: '',
            address: '',
            postal: '',
            zip: '',
            project: '',
            other: '',
            orderEmail: ''
        }
    };

    buildMailBody() {
        let body = [];

        body.push('Beställning:')
        if (this.state.mail.fastfoodguide23) {
            body.push('Fastfoodguide 2023: 3.895 SEK exkl moms');
        }

        if (this.state.mail.fastfoodguide23package) {
            body.push('Fastfoodguide 2023 E-bok och och presentationsmaterial: 4.890 SEK exkl moms');
        }

        if (this.state.mail.fastfoodguide23presentation) {
            body.push('Fastfoodguide 2023 presentationsmaterial: 2.750 SEK exkl moms');
        }


        body.push('Beställare:');
        body.push(`Namn: ${this.state.mail.name}`);
        body.push(`Epost: ${this.state.mail.email}`);
        body.push(`Företag: ${this.state.mail.company}`);
        body.push(`Org.Nr: ${this.state.mail.org}`);
        body.push(`Telefon: ${this.state.mail.phone}`);
        body.push(`Faktureringsuppgifter:`);
        body.push(`Adress: ${this.state.mail.address}`);
        body.push(`Postnummer: ${this.state.mail.zip}`);
        body.push(`Ort: ${this.state.mail.postal}`);
        body.push(`Projektnr: ${this.state.mail.project}`);
        body.push(`Övrig info: ${this.state.mail.other}`);
        body.push(`Epost faktura: ${this.state.mail.orderEmail}`);
        body.push('');
        body.push('--');
        body.push('Detta meddelande är skickat från Delfi MarknadsPartner AB - www.delfi.se')
        
        return body.join('\n');  
    }

    async sendMail(e) {
        e.preventDefault();

        if (!this.validate()) {
            return;
        }
      
        this.setState({
            isLoading: true
        });

        //Build mail body
        const body = this.buildMailBody();
        const res = await fetch('/api/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                subject: 'Beställning Fastfoodguide',
                cc: this.state.mail.email,
                mailBody: body
            })
        });

        await res.json();
        this.setState({
            isValid: true,
            isNameError: false,
            isEmailError: false,
            isOrderEmailError: false,
            isLoading: false,
            isSaved: true,
            mail: {
                fastfoodguide23: false,
                fastfoodguide22: false,
                fastfoodguide21: false,
                fastfoodguide20: false,
                numberOfFastfoodguides: 1,
                presentation: false,
                name: '',
                email: '',
                company: '',
                org: '',
                phone: '',
                address: '',
                postal: '',
                zip: '',
                project: '',
                other: '',
                orderEmail: ''
            }
        }, () => {
            /*setTimeout(() => {
                this.setState({ 
                    isSaved: false 
                });
            }, 6000);*/
        });
    }

    toggleModal() {
        this.props.close();

        this.setState({
            isValid: true,
            isNameError: false,
            isEmailError: false,
            isOrderEmailError: false,
            isLoading: false,
            isSaved: false,
            mail: {
                fastfoodguide23: false,
                fastfoodguide23package: false,
                fastfoodguide23presentation: false,
                name: '',
                email: '',
                company: '',
                org: '',
                phone: '',
                address: '',
                postal: '',
                zip: '',
                project: '',
                other: '',
                orderEmail: ''
            }
        });
    }

    updateValues(param, value) {
        const mail = {...this.state.mail};

        mail[param] = value;
        this.setState({
            mail
        });
    }

    validate() {
        let isValid = true;
        let isNameError = false;
        let isEmailError = false;
        let isOrderEmailError = false;

        if (this.state.mail.name === '') {
            isValid = false;
            isNameError = true;
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.mail.email)) {
            isValid = false;
            isEmailError = true;
        }

        if (this.state.mail.orderEmail !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.mail.orderEmail)) {
            isValid = false;
            isOrderEmailError = true;
        }

        this.setState({
           isValid,
           isNameError,
           isEmailError,
           isOrderEmailError
        });

        return isValid;
    }

    render () {
        return (
            <div className={classNames({
                'order-form': true,
                'order-form--is-loading': this.state.isLoading,
                'order-form--is-finished': this.state.isSaved
            })}>
                {!this.state.isSaved &&
                    <form method="post" onSubmit={e => this.sendMail(e)}>
                        <div className="order-form__products">
                            <div className="order-form__row">
                                <label>
                                    <div className="order-form__controls">    
                                        <Checkbox name="fastfoodguide23" onChange={() => this.updateValues('fastfoodguide23', !this.state.mail.fastfoodguide23)} checked={this.state.mail.fastfoodguide23} />
                                        <div className="order-form__label">
                                            <span className="order-form__label-heading">Fastfoodguide 2023</span>
                                            <span className="order-form__label-subheading">E-publikation</span>
                                        </div>
                                    
                                    </div>
                                </label>
                                <div className="order-form__price"><strong>3.895:-</strong> exkl moms</div>
                            </div>

                            <div className="order-form__row">
                                    <label>
                                        <div className="order-form__controls">    
                                            <Checkbox name="fastfoodguide23package" onChange={() => this.updateValues('fastfoodguide23package', !this.state.mail.fastfoodguide23package)} checked={this.state.mail.fastfoodguide23package} />
                                            <div className="order-form__label">
                                                <span className="order-form__label-heading">Fastfoodguide 2023</span>
                                                <span className="order-form__label-subheading">E-publikation och presentationsmaterial</span>
                                            </div>
                                        
                                        </div>
                                    </label>
                                    <div className="order-form__price"><strong>4.890:-</strong> exkl moms</div>
                                </div>

                            <div className="order-form__row">
                                    <label>
                                        <div className="order-form__controls">    
                                            <Checkbox name="fastfoodguide23presentation" onChange={() => this.updateValues('fastfoodguide23presentation', !this.state.mail.fastfoodguide23presentation)} checked={this.state.mail.fastfoodguide23presentation} />
                                            <div className="order-form__label">
                                                <span className="order-form__label-heading">Fastfoodguide 2023</span>
                                                <span className="order-form__label-subheading">Presentationsmaterial</span>
                                            </div>
                                        
                                        </div>
                                    </label>
                                    <div className="order-form__price"><strong>2.750:-</strong> exkl moms</div>
                                </div>
                        </div>


                        <h2 className="order-form__sub-heading">Beställare</h2>
                        <label className="order-form__label">Namn*</label>
                        <Input name="name" 
                            onChange={(e) => this.updateValues('name', e.currentTarget.value)} 
                            value={this.state.mail.name} 
                            modifiers={{
                                'form__input--is-error': this.state.isNameError
                            }}/> 

                        <label className="order-form__label">Epost*</label>
                        <Input name="email" 
                            onChange={(e) => this.updateValues('email', e.currentTarget.value)} 
                            value={this.state.mail.email} 
                            modifiers={{
                                'form__input--is-error': this.state.isEmailError
                            }} /> 

                        <label className="order-form__label">Företag</label>
                        <Input name="company" onChange={(e) => this.updateValues('company', e.currentTarget.value)} value={this.state.mail.company} /> 

                        <label className="order-form__label">Org.Nr</label>
                        <Input name="org" onChange={(e) => this.updateValues('org', e.currentTarget.value)} value={this.state.mail.org} /> 

                        <label className="order-form__label">Telefon</label>
                        <Input name="phone" onChange={(e) => this.updateValues('phone', e.currentTarget.value)} value={this.state.mail.phone} /> 
                        
                        <h2 className="order-form__sub-heading order-form__sub-heading--air-top">Faktureringsuppgifter</h2>
                        <label className="order-form__label">Adress</label>
                        <Input name="address" onChange={(e) => this.updateValues('address', e.currentTarget.value)} value={this.state.mail.address} /> 
                        
                        <label className="order-form__label">Postnummer</label>
                        <Input name="postal" onChange={(e) => this.updateValues('zip', e.currentTarget.value)} value={this.state.mail.zip} /> 

                        <label className="order-form__label">Ort</label>
                        <Input name="postal" onChange={(e) => this.updateValues('postal', e.currentTarget.value)} value={this.state.mail.postal} /> 

                        <label className="order-form__label">ev Ordernr/Projekt</label>
                        <Input name="project" onChange={(e) => this.updateValues('project', e.currentTarget.value)} value={this.state.mail.project} /> 

                        <label className="order-form__label">Ev Epost faktura</label>
                        <Input name="orderEmail" 
                            onChange={(e) => this.updateValues('orderEmail', e.currentTarget.value)} 
                            value={this.state.mail.orderEmail} 
                            modifiers={{
                                'form__input--is-error': this.state.isOrderEmailError
                            }} /> 

                        <label className="order-form__label">Övrig info - Ange önskemål om tryckt publikation finns	</label>
                        <Textarea name="other" onChange={(e) => this.updateValues('other', e.currentTarget.value)} value={this.state.mail.other} /> 
                        {this.state.isNameError && this.state.isEmailError &&
                            <p className="order-form__error">🥸 Namn och Epost är obligatoriska fält. </p>
                        }
                        {!this.state.isNameError && this.state.isEmailError &&
                            <p className="order-form__error">🥸 Epost är ett obligatoriskt fält.</p>
                        }
                        {this.state.isNameError && !this.state.isEmailError &&
                            <p className="order-form__error">🥸 Namn är ett obligatoriskt fält.</p>
                        }
                         {this.state.isOrderEmailError &&
                            <p className="order-form__error">🥸 Epost faktura är inte en giltig epost.</p>
                        }
                        <SaveButton
                                type="submit"
                                isLoading={this.state.isLoading}
                                isSaved={this.state.isSaved} 
                                text="Skicka beställning" 
                                textSaving="Skickar"
                                textSaved="Tack för din beställning!"
                                attr={{
                                    disabled: this.props.isSaving
                                }}
                                modifiers={{
                                    'button--large': true,
                                    'demo-form__submit': true,
                                    'order-form__submit': true
                                }}>Skicka beställning</SaveButton>
                        <button className="order-form__cancel" type="button" onClick={() => this.toggleModal()}>Avbryt</button>
                    </form>
                }
                {this.state.isSaved &&
                    <>
                        <p className="order-form__success">Tack för din beställning! Vi har nu mottagit din beställning och en bekräftelse är skickad till den e-postadress som du angivit i formuläret.</p>
                        <Success isFinished={this.state.isSaved} />
                        <div className="districts__takeover-controls">                                
                            <button onClick={() => this.toggleModal()} className="order-form__close-btn" type="button">Stäng</button>
                        </div>                        
                    </>
                }
                <button className="takeover__close" onClick={() => this.toggleModal()} type="button"></button>
            </div>
        );
    }
}

export default FastfoodOrderForm;
