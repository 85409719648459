import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Button = ({onClick, ...props}) => {
    return (
        <button className={classNames({
            'button': true,
            ...props.modifiers
        })} type={props.type} onClick={onClick}>{props.children}</button>
    );
};

export default Button;