import React, { useState } from 'react';
import classNames from 'classnames';
import Takeover from '../takeover';
import Button from '../button';
import { FastfoodOrderForm, GroceryMapOrderForm } from '../order-forms';
import ff from '../../images/content/ff2023.png';
import fb from '../../images/content/fb2022.png';
import dk from '../../images/content/dagligvarukartan-24.jpg';
import dkPDF from '../../images/content/Dagligvarukartan21_v4.pdf';
import './styles.scss';

const Publications = props => {

    const [isFastfoodOrderFormOpen, toggleFastfoodOrderForm] = useState(false);
    const [isGroceryMapOrderFormOpen, toggleGroceryMapOrderForm] = useState(false);
    const [isReadMoreOpen, toggleReadMore] = useState(false);

    return (
        <div className="publications">

            <div className="publications__item">
                <div className="publications__item-content">
                    <div className="publications__item-img">
                        <div className="publications__img-ratio">
                            <img src={fb} alt="" />
                        </div>
                    </div>
                    <div className="publications__item-content-text">
                        <h2 className="publications__item-headig">Foodservice branschrapport 2022</h2>
                        <p>
                            På uppdrag av branschen (ECR Sverige) har vi på Delfi Marknadspartner tagit fram en helt ny totalmarknadskartläggning av den svenska restaurang- och storköksmarknaden, inklusive framtidsutsikter. 
                        </p>
                        {isReadMoreOpen &&
                            <div>
                                <p>
                                    Med denna rapport kan vi nu äntligen få en samlad bild av marknaden och dess utveckling, vilket är något som vi länge saknat och som vi tror är viktigt för att vi tillsammans skall kunna diskutera, förstå och analysera vår bransch i mötet med kunder, branschkollegor, ägare, politiker och media.
                                </p>
                                <p>
                                    Rapporten bygger på insamling, bearbetning, och analys av information från en lång rad olika datakällor, vilket kompletterats med riktade enkäter och djupintervjuer med centrala branschföreträdare.
                                </p>
                            </div>
                        }
                        <button className={classNames({
                            'publications__read-more': true,
                            'publications__read-more--is-open': isReadMoreOpen
                        })} type="button" onClick={() => toggleReadMore(!isReadMoreOpen)}>Visa {isReadMoreOpen ? 'mindre' : 'mer'}</button>
                    </div>
                </div>
                <div className="publications__item-cta">
                    <a href="https://dabas.blob.core.windows.net/delfinext-public-web/Branschrapport-Foodservice-22.pdf" target="_blank" className="button button--large button--block publications__add-to-cart" download>Ladda ned pdf</a>

                    <p className="publications__price"><strong>Gratis</strong></p>
                </div>
            </div>

            <div className="publications__item">
                <div className="publications__item-content">
                    <div className="publications__item-img">
                        <div className="publications__img-ratio">
                            <img src={ff} alt="" />
                        </div>
                    </div>
                    <div className="publications__item-content-text">
                        <h2 className="publications__item-headig">Delfi Fastfoodguide</h2>
                        <p>
                            Delfi Fastfoodguide är marknadens enda kompletta kartläggning av en av de snabbast växande delarna av restaurangsektorn. Med en aktuell sammanställning och analys av det gångna fastfood året, beskriver vi den svenska fastfoodmarknaden, dess utveckling och trender.
                        </p>
                    </div>
                </div>
                <div className="publications__item-cta">
                    <Button 
                        type="button"
                        onClick={() => {
                            document.body.classList.remove('modal-open');
                            document.body.classList.add('modal-open');
                            toggleFastfoodOrderForm(true);
                        }}
                        modifiers={{
                        'button--large': true,
                        'button--block': true,
                        'publications__add-to-cart': true
                    }}>Beställ guiden</Button>

                    <Takeover isOpen={isFastfoodOrderFormOpen} modifiers={{
                        'takeover--wide': true
                    }} 
                    heading="BESTÄLL FASTFOODGUIDEN">
                        <FastfoodOrderForm close={() => {
                                document.body.classList.remove('modal-open');
                                toggleFastfoodOrderForm(false);
                            }} />
                    </Takeover>

                    <p className="publications__price"><strong>3 895:-</strong> ex. moms</p>
                </div>
            </div>

            <div className="publications__item">
                <div className="publications__item-content">
                    <div className="publications__item-img">
                        <div className="publications__img-ratio">
                            <img src={dk} alt="" />
                        </div>
                    </div>
                    <div className="publications__item-content-text">
                        <h2 className="publications__item-headig">Dagligvarukartan 2024</h2>
                        <p>
                            Vilka ökar försäljningen och tar marknadsandelar? Dagligvarukartan ger en visuell överblick av utvecklingen i Sverige. Delfi tar fram dagligvarukartan i samarbete med DLF.
                        </p>
                    </div>
                </div>
                <div className="publications__item-cta">
                    <a href="https://kundservice.delfi.se/wp-content/uploads/2024/11/Dagligvarukartan24.pdf" className="button button--large button--block publications__add-to-cart" download>Ladda ned pdf</a>
                    <Button 
                        onClick={() => {
                            document.body.classList.remove('modal-open');
                            document.body.classList.add('modal-open');
                            toggleGroceryMapOrderForm(true);
                        }}
                        modifiers={{
                            'button--large': true,
                            'button--block': true,
                            'button--secondary': true,
                            'publications__add-to-cart': true
                        }}>Beställ trycksak</Button>

                    <Takeover isOpen={isGroceryMapOrderFormOpen} modifiers={{
                            'takeover--wide': true
                        }}
                        heading="Beställning av Dagligvarukartan">
                            <GroceryMapOrderForm close={() => {
                                document.body.classList.remove('modal-open');
                                toggleGroceryMapOrderForm(false);
                            }} />
                    </Takeover>

                    <p className="publications__price"><strong>Gratis</strong></p>
                </div>
            </div>
        </div>
    );
};

export default Publications;
