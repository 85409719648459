import React from 'react';
import './styles.scss';

const Checkbox = props => {
    return (
        <>
             <input type="checkbox" {...props} /><span className="form__checkbox"></span>
        </>
    );
};

export default Checkbox;